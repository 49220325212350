form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
}

.form__title {
    text-align: center;
}

.btn__form {
    display: block;
    margin: 5px auto;
}

input,
textarea {
    height: 50px;
    width: 100%;
    margin: 5px 0;
    padding: 15px;
    font: 20px "Roboto";
    background-color: @input;
    border: none;
    border-radius: 12px;
    color: @hl;
    resize: none;

    &.half {
        width: 49%;

        @media @m {
            width: 100%;
        }

        &--12869f3 {
            display: none;
        }
    }

    &.comment {
        height: 200px;
    }
}

::placeholder {
    color: @hl;
}

.inactive {
    opacity: 0.5;
    cursor: default;
}