@import (inline) '../css/swiper-bundle.css';
@import '_mixins/reset';
@import '_mixins/colors';
@import '_mixins/fonts';
@import '_mixins/constants';
@import '_mixins/mediaQueries';
@import '_components/header';
@import '_components/footer';
@import '_components/about';
@import '_components/buggies';
@import '_components/carousel';
@import '_components/shop'; 
@import '_components/shopItem';
@import '_components/shopForm';
@import '_components/slide';
@import '_components/contact';


*, *:before, *:after {
    box-sizing: inherit; 
} 
html, main {
    background: @hl;
}
html, body {
    width: 100%;
    display: block;
    position: relative;
    box-sizing: border-box;
    height: auto;
    background: @hl;
}
#intro {
    overflow: hidden;
}
body {
    display: block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.6em;
    color: @w;
    background: @hl;
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;
    &.body--no-scroll {
        overflow: hidden;
    }
}
#content {
    min-height: 100vh;
}
main {
    display: flex;
    position: relative;
    min-height: 100vh;
    flex: 1 0 auto;
    z-index: 5;
    background: @hl;
    a {
        border-bottom: 3px solid @hl;
    }
}
a {
    text-decoration: none;
    color: @hl;
}
a, button, input[type="submit"] {
    cursor: pointer;
}
img {
    width: 100%;
    height: auto;
    display: block;
}
button {
    -webkit-appearance: none;
    border: none;
    border-radius: 12px;
    max-width: 437px;
    width: 100%;
    height: 68px;
    font-size: 20px;
    font-weight: bold;
    background-color: @w;
    color: @hl;
}
video {
    width: 100%;
    height: auto;
    display: block;
}
h1, h2, h3 {
    margin: 0px;
    //hyphens: auto;
    font-weight: 900;
    text-decoration: none;
    line-height: 1.4em;
    a {
        color: @hl;
        border-bottom: 3px solid transparent;
    }
}
h1, .headline {
    font-weight: normal;
    font-size: 57px;
    line-height: 62px;
    i {
        font-weight: bold;
    }
    @media @xl {
        font-size: 40px;
        line-height: 42px;
    }
    @media @s {
        font-size: 30px;
                line-height: 32px;
    }
}
h2 {
    font-size: 38px;
    line-height: 42px;
    font-weight: bold;
    color: @w;
    margin: 0px;
    display: inline-block;
    hr {
        margin: 5px 0px;
    }
    i {
        font-weight: normal;
    }
    @media @xl {
        font-size: 28px;
    }
}
h3 {
    font-size: 1em;
}
p {
    margin: 0px;
    margin-bottom: 0px;
}
.leftbound {
    object-position: left;
}
.rightbound {
    object-position: right;
}
.text_outline {
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: @w;
    -webkit-text-fill-color: transparent;
    @media @xl {
        -webkit-text-stroke-width: 2px;
    }
    @media @s {
        -webkit-text-stroke-width: 1px;
    }
}
em, i {
    font-style: italic;
}
::selection {
    background: @hl;
}
:focus {
    outline: 0px transparent solid;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 150px @hl inset !important;
}
.flexspacer {
    flex: 1 1 0%;
}
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
.loader {
    width: 100%;
    text-align: center; 
    img {
        height: 100px;
        width: 100px;
        display: inline-block;
    }
}
.mmtext {
    text-align: justify;
}

#bg, .slide {
    width: 100vw;
    height: 100vh;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.slide {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow-x: hidden;
}
.hide {
    opacity: 0;
    visibility: hidden;
}

span {
    &.hl {
        color: @hl;
    }
}
#prev {
    position: fixed;
    left: 10px;
    top: 50%;
    width: 80px;
    height: 80px;
    transform: translateY(-50%);
    @media @xl {
        left: 0px;
        width: 40px;
        height: 40px;
    } 
    span {
        display: block;
        transform: translate(0px, 10px) rotate(-90deg);
        color: @w;
    }
}
#next {
    position: fixed;
    right: 10px;
    top: 50%;
    width: 80px;
    height: 80px;
    transform: translateY(-50%);
    @media @xl {
        right: 0px;
        width: 40px;
        height: 40px;
    }
    span {
        display: block;
        transform: translate(0px, 45px) rotate(90deg);
        color: @w;
        @media @xl {
            transform: translate(0px, 7px) rotate(90deg);
        }
    }
}

main.default {
    display: block;
}