.swiper {
    width: 100%;
    position: relative;
    user-select: none;

    img {
        height: calc(100vh - 195px);
        width: 100%;
        object-fit: cover;
    }
    
    &__large {
        img {
            object-position: center;
        }
    }
    &__small {
       max-height: 800px;
       @media @s {
        height: 100vw;
       }
    }
    &--horizontal {
        @media @s {
            display: none;
        }
    }

    &--vertical {
        display: none;
        @media @s {
            display: block;
        }
    }

    .swiper-button-next:after, .swiper-button-prev:after {
        color: @hl;
    }
}