@font-face {
    font-family: "Roboto";
    font-display: block;
    font-weight: 300;
    src: url("https://cdn.mmsrv.de/fonts/Roboto/Regular.ttf") format('truetype');
}
@font-face {
    font-family: "Roboto";
    font-display: block;
    font-weight: 900;
    src: url("https://cdn.mmsrv.de/fonts/Roboto/Black.ttf") format('truetype');
}