#buggies {

    main {
        background-color: @hl;
    }
  
    h2, h3, p {
        line-height: normal;
    }
  
    h2 {
        font-size: 68px;
        margin-bottom: @spacing;
        @media @xxl {
            font-size: 48px;
        }
        @media @xl {
            font-size: 36px;
        }
        @media @s {
            font-size: 28px;
        }
    }
    
    .swiper--title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: @spacing;
        
        .text {
            a {
                text-decoration: underline;
            }

            h2, h3 {
                color: @w;
            }

            h2 {
                font-size: 64px;
            }

            &--italic {
                font-style: italic;
            }

            .title--hl {
                color: @w;
            }

            .spacer {
                outline: none;
                border: 1px solid @w;
                max-width: 475px;
                width: 100%;
                margin-left: -70px;
            }
        }

        @media @xl {
            flex-direction: column;
            align-items: flex-start;

            .text {   
                margin-bottom: @spacing;
                .spacer {
                    margin-left: -95px;
                }
            }
        }

        h2 {
            margin-bottom: 0;
        }
    }

    h3 {
        font-size: 42px;
        @media @xxl {
            font-size: 36px;
        }
        @media @xl {
            font-size: 28px;
        }
        @media @s {
            font-size: 24px;
        }
    }
  
    section {
        margin: 35px 70px;
        @media @xl {
            margin: (@spacing * 2) (@spacing / 2);
        }
        @media @m {
            margin: (@spacing * 2) (@spacing / 4);
        }

    }

    .language {
        font-size: 24px;
        
        a {
            transition: all .5s ease;
            font-weight: 700;
            color: @w;
            border-bottom: 3px solid @w;

            &:hover {
                margin-left: 5px;
            }
        }
    }
  
    .shop--headline {
        @media @xs {
            font-size: 24px;
        }
    }

    .shop--text {
        font-size: 24px;
        font-weight: 700;
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: (@spacing / 2);
        @media @xl {
            font-size: 18px;
            font-weight: 400;
            grid-template-columns: 3fr 1fr;
        }
        &__lower {
            grid-template-columns: 9fr 1fr;
        }
        a {
            border: none;
        }
    }

    .arrow {
        height: 100%;
        width: auto;
        @media @xl {
            justify-self: center;
        }
    }

    .decoration {
        display: flex;
        &--text {
            color: @hl;
            width: min-content;
            position: absolute;
            bottom: 20px;
            right: 20px;
            z-index: 99;
        }
        @media @l {
            align-items: flex-end;
        }
    }

    .content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: (@spacing / 2);
        @media @xxl {
            grid-template-columns: 1fr;
            gap: @spacing;
        }
    }

    
    
    .detail {
        &--title {
            margin-bottom: (@spacing / 2) ;
        }
        &--text {
            font-size: 24px;
            font-weight: 700;

            .dropdown {
                margin-top: (@spacing / 2);

                &:first-of-type{
                    margin-top: @spacing;
                }

                .info--label{
                    cursor: pointer;
                    transition: all .5s ease;

                    &:hover {
                        margin-left: 5px;
                    }

                    &::after {
                        margin-left: .25em;
                        content: '+';
                        color: @w;
                    }
                }

                input.hidden {
                    display: none;

                    &~ ul {
                        height: 0;
                        padding-left: (@spacing / 2);
                        overflow: hidden;
                        opacity: 0;
                        transition: all 1s ease;

                        li {
                            font-weight: 400;
                            margin-bottom: 5px;
                            b {
                                font-weight: 700;
                            }
                        }

                        li::before {
                            content: '\2022';
                            color: @w;
                            font-weight: bold;
                            display: inline-block;
                            width: .5em;
                            margin-left: -1em;
                        }
                    }

                    &:checked {

                        &~ ul {
                            margin: (@spacing / 2) 0;
                            height: auto;
                            opacity: 1;
                        }

                        &~ .info--label::after {
                            content: '-';
                        }
                    }
                }
            }
        }
    }
    
    .detail--list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: (@spacing / 2);
        height: fit-content;

        .detail--cell {
            max-width: 500px;
            
            h3 {
                text-transform: uppercase;
            }

            p {
                font-size: 20px;
                @media @s {
                    font-size: 16px;
                }
            }
        }

        @media @m {
            grid-template-columns: 1fr;
            gap: @spacing;
        }
    }

    #footer {
        p {
            color: @w;
        }
    }
}