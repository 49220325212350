#contact {
    header {
        position: absolute;
    }

    .contentWrap {
        padding: @lr;

        @media @xl {
            padding: 35px;
        }

        @media @xs {
            padding: 0px;
        }
    }

    .bg {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .bg__image {
        height: 100%;
        position: absolute;
        object-fit: cover;
    }

    .content {
        display: flex;
        margin-top: 100px;
        min-height: 66%;
        height: auto;
        width: 100%;
        background-color: @hl;
        padding: 40px;

        @media @xs {
            padding: 10px;
        }

        @media @xxl {
            flex-wrap: wrap;
            align-items: center;
        }

        position: relative;
        margin: 160px auto 50px auto;

        p {
            margin: 20px;
        }
    }

    form {
        width: calc(100% - 35px);

        @media @xxl {
            width: 100%;
        }
    }

    #formWrap {
        button {
            display: none;
        }

        &.show {
            button {
                display: block;
            }
        }
    }

    .btn__form {
        margin-top: 40px;
    }

    .contact__info {
        background-color: @w;
        border-radius: 12px;
        margin-top: 5px;
        margin-bottom: 143px;
        font-size: 23px;
        color: @hl;

        @media @xxl {
            width: 100%;
            order: -1;
            margin-bottom: 40px;
        }
    }

    b {
        font-weight: 800;
    }
}

#sendSuccessfully {
    display: none;
    margin-top: 20px;

    &.show {
        display: block;
    }
}