#shop__item, #application {
    header {
        position: absolute;
    }
    main {
        margin-bottom: 0px;
        @media @xl {
            flex-wrap: wrap;
        }
    }
    #left, #right {
        width: 50%;
        min-height: 100vh;
        height: auto;
        position: relative;
        display: block;
        border: none;
        background-color: @hl;
        #inline__header {
            display: none;
            position: fixed;
            z-index: 10;
            top: 0;
            right: 0;
            left: 50%;
            background-color: @hl;
            a {
                color: @w;
            }
        }
        &>.img {
            display: block;
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .shop__content p {
            color: @w;
            margin-bottom: 20px;
        }
        hr {
            display: inline-block;
            width: 100%;
            margin-left: 0;
            outline: none;
            border: 1px solid @w;
            &.job--divider {
                margin-top: 43px;
            }
        }
        b {
            font-weight: 900;
        }
        .lower {
            font-size: 25px;
        }
        .content {
            font-size: 20px;
            margin-top: 35px;
        }
        .hover {
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgb(232 93 44 / 75%);
            text-align: center;
        }
        &:hover {
            .hover {
                opacity: 1;
            }
        }
        .shop__content {
            display: flex;
            align-items: flex-end;
            background-color: @hl;
            padding: 50px;
            padding-top: 200px;
            min-height: 100vh;
        }
        .expand {
            text-align: center;
            margin-bottom: 20px;
        }
        .shop__list {
            margin-bottom: 55px;
            &.hide {
                display: none;
            }
        }
    }
    #right footer, #left footer {
        position: relative;
        background: @hl;
        img {
            object-fit: inherit;
            margin-top: 10px;
            width: 40px;
            height: 40px;
        }
        .social {
            margin: 0;
            width: 25px;
            height: 25px;
        }
    }
    &.right {
        #left {
            position: fixed;
            top: 0;
            right: 50%;
            bottom: 0;
            left: 0;
            .shop__content {
                text-align: end ;
            }
            hr {
                margin-right: 0;
                margin-left: auto;
            }
        }
        #right {
            margin-left: 50%;
        }
    }
    &.left {
        #right {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 50%;
        }
    }
    @media @xl {
        header {
            position: absolute;
        }
        &.right, &.left {
            #left, #right {
                position: relative;
                display: block;
                width: 100%;
                margin: 0px;
                height: auto;
                bottom: inherit;
                min-height: 100px;
                left: inherit;
                right: inherit;
                .img {
                    height: calc(100vh - 240px);
                }
                footer img {
                    height: 40px;
                }
                .shop__content {
                    min-height: 100px;
                    padding: 35px;
                    padding-top: 50px;
                }
                #inline__header {
                    display: none;
                }
            }
        }
        &.right main {
            display: flex;
            flex-direction: column;
        }
        &.left main {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    #showForm {
        &.hide {
            display: none;
        }
    }

    #formWrap {
        display: none;
        &.show {
            display: block;
        }
    }
    #sendSuccessfully {
        display: none;
        margin-top: 20px;
        &.show {
            display: block;
        }
    }
}