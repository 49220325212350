#about, #start {
    @media @max {
        header {
            position: absolute;
        }
        main {
            position: relative;
            display: block;
        }
        .slide {
            position: relative;
            overflow: inherit;
            bottom: inherit;
            height: auto;
            &.hide {
                display: none;
            }
            img {
                height: calc(100vh - 166px);
                &.bottom {
                    object-position: bottom;
                }
            }
            .slide__box {
                position: relative;
                background: @hl;
                top: inherit;
                height: auto;
                &.history {
                    background: @g;
                }
            }
        }
    }
    @media @xl {
        header {
            position: absolute;
        }
        main {
            position: relative;
            display: block;
        }
        .slide {
            position: relative;
            overflow: inherit;
            bottom: inherit;
            height: auto;
            &.hide {
                display: none;
            }
            img {
                height: calc(100vh - 240px);
            }
            .slide__box {
                position: relative;
                background: @hl;
                top: inherit;
                height: auto;
                &.history {
                    background: @g;
                }
            }
        }
    }
    @media @xl {
        .slide .slide__box {
            padding: 35px 50px;
        }
    }
    .historia__img--mobile {
        display: none;
        object-position: center;
        @media @s {
            display: block;
        }
    }
    @media @s {
        .historia__img {
            display: none;
        }
    }
}