#shop {
    main {
        display: flex;
        #left, #right {
            width: 50%;
            height: 100vh;
            position: relative;
            display: block;
            .img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .hover {
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: @hl-trans;
                text-align: center;
                .headline {
                    font-size: 37px;
                    line-height: 42px;
                    margin-bottom: 0;
                }
                .lower {
                    font-size: 16px;
                }
            }
            &:hover {
                .hover {
                    opacity: 1;
                }
            }
        }
        p {
            color: @w;
            margin-bottom: 20px;
        }
        hr {
            width: 490px;
            margin-left: 0;
            outline: none;
            border: 1px solid @w;
            &.job--divider {
                width: auto;
                margin-top: 43px;
            }
        }
        b {
            font-weight: 900;
        }
        .upper {
            font-size: 57px;
            margin-bottom: 50px;
        }
        .lower {
            font-size: 25px;
        }
        @media @xl {
            flex-wrap: wrap;
            a {
                border: none;
            }
            #left, #right {
            width: 100%;
            height: 50vh;
            position: relative;
            display: block;
            }
        }
    }
}