header {
    display: flex;
    position: fixed;
    justify-content: space-between;
    z-index: 100;
    top: 0px;
    left: 0px;
    right: 0px;
    padding: 60px @lr 0px @lr;
    &.noscroll {
        position: absolute;
        @media @xxxl {
            position: fixed;
        }
    }
    @media @xl {
        padding: 30px 10px 0px 10px;  
    }
}
#logo {
    position: relative;
    display: block;
    width: 226px;
    height: 100px;
    @media @xl {
        width: 126px;
        height: 50px;
    }
}

nav ul.alt a {
    color: @g;
}

nav ul li {
    .nav--hover {
        position: absolute;
        display: none;
        opacity: 0;
        &.active {
            display: block;
            opacity: 1;
            color: @w;
        }
        @media @xxxl {
            display: block;
            opacity: 1;
            position: relative;
            padding-top: 30px;
            padding-bottom: 0px;
        }
    }
    &:hover {
        .nav--hover {
            display: block;
            opacity: 1;
        }
    }
    a:hover {
        color: @w;
    }
}

nav li.act a{
    color: @w;
    white-space: nowrap;
}
#header {
    transition: all .5s;
    &.showNav {
        @media @xxxl {   
            position: fixed;
            transform: translateX(-350px);
        }
    }
}

nav {
    #burger {
        display: none;
        position: absolute;
        top: 40px;
        right: 400px;
        color: @hl;
        width: 30px;
        transition: all .5s;
        img {
            height: 30px;
            width: 30px
        }
        @media @xxxl {
            display: block;
        }
        &.active {
            @media @xs {
                right: 292px;
            }
        }
    }
    @media @xxxl {
        transform: translateX(360px);
        height: 100vh;
        margin: 0;
        position: fixed;
        top: 0;
        right: 0;
        background-color: @hl-trans;
    }
}

nav ul {
    display: flex;
    @media @xxxl {
        display: block;
        font-size: 30px;
        :first-child {
            margin-top: 100px;
        }
        li {
            margin: 30px 10px;
        }
        .hidden {
            right: -250px;
        }
    }
    a {
        padding: 5px 15px;
        @media @xxxl {
            padding: 10px 15px;
            color: @w;
        }
    }
}