.hide .slide__box {
    transform: translate(100%, -339px);

    @media @xlHeight {
        transform: translate(0px, 100px);
    }

    @media @xxl {
        transform: translate(0px, 100px);
    }

    .headline,
    .content {
        transform: translateX(100%);
        opacity: 0;

        @media @xlHeight {
            transform: translate(0%, 100px);
        }
    }
}

.slide__box {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0%, -339px);
    transition: transform .6s ease;
    padding: 75px 60px;
    width: 856px;
    // max-height: 715px;
    background-color: @hl-trans;

    &.history {
        background: rgba(56, 96, 46, 0.75);

        .content {
            margin-top: 50px;
        }
    }

    hr {
        width: 355px;
        outline: none;
        border: 1px solid @w;
        margin-left: -60px;

        &.job--divider {
            width: auto;
            margin-top: 43px;
        }
    }

    h2 {
        transform: translateX(0%);
        transition: all .5s ease .1s;
        opacity: 1;
        letter-spacing: 15px;
    }

    .content {
        margin-top: 40px;
        font-size: 20px;
        line-height: 33px;
        color: @w;
        width: 620px;
        opacity: 1;
        transition: all .5s ease .1s;
    }

    @media @lHeight {
        transform: translate(0%, 0px);
        width: 100%;
        padding: 35px @lr;
        top: calc(100% - 150px);

        .content {
            max-width: 620px;
            width: 100%;
            font-size: 16px;
            line-height: 23px;
        }

        hr {
            max-width: 475px;
            width: 100%;
            margin-left: -70px;

            &.job--divider {
                max-width: auto;
                margin-top: 43px;
            }
        }
    }

    @media @max {
        transform: translate(0%, 0px);
        width: 100%;
        padding: 35px @lr;
        top: calc(100% - 150px);
        background: @hl;

        &.history {
            background: @g;
        }

        .content {
            max-width: 620px;
            width: 100%;
            line-height: 23px;
        }

        hr {
            max-width: 475px;
            width: 100%;
            margin-left: -70px;

            &.job--divider {
                max-width: auto;
                margin-top: 43px;
            }
        }
    }
}