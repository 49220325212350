footer {
    height: 80px;
    @media @s {
        height: auto;
    }
    padding: 10px @lr;
    width: 100%;
    display: flex;
    background: @hl;
    &.alt {
        background: @g;
    }
    #footer__logo {
        width: 100px;
        img {
            margin-top: 10px;
            width: 40px;
            height: 40px;
        }
    }
    #footer__text {
        display: flex;
        padding-top: 3px;
        width: 100%;
        align-items: center;
        justify-content: center;
        color: @w;
    }
    #socials {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }
    .social {
        display: inline-block;
        height: 25px;
        width: 25px;
    }
    #footer__text a {
        color: @w;
        width: 25px;
        height: 25px;
    }
    @media @xl {
        padding: 10px;
    }
    @media @xs {
        flex-direction: column;
        align-items: center;
        #footer__logo { 
            width: auto;
        }
        #footer__text {
            margin: 10px auto;
        }
    }
}